import React from 'react';
import PropTypes from 'prop-types';
import { createComponent, } from 'react-fela';
import HtzLink from '../HtzLink/HtzLink';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';

const creditPropTypes = {
  /**
   * Author name/Credit text
   */
  contentName: PropTypes.string.isRequired,
  /**
   * Authors page url
   */
  url: PropTypes.string,
};

const creditDefaultProps = {
  url: null,
};

const LinkStyled = createComponent(
  theme => ({
    color: 'currentcolor',
    ':hover': {
      textDecoration: 'underline',
    },
    ':focus': {
      textDecoration: 'underline',
    },
  }),
  HtzLink,
  [ 'href', 'content', 'onClick', ]
);

function Credit({ contentName, className, onClick, ...props }) {
  const isWebView = useWebViewChecker();
  const lineage = useBreadcrumbsData();

  let url = props.url;
  const name = (contentName || '').trim();

  if (!name) return null;

  if (isWebView) {
    if (typeof url === 'string' && url.toLowerCase().includes('ty-writer')) {
      const urlParts = /(?:.+)?([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})(?:.+)?/.exec(url);

      if (Array.isArray(urlParts) && urlParts[1]) {
        const writerId = urlParts[1];
        url = `/ty-writer/${writerId}?author&_app=true`;
      }
    }
  }

  return (
    <address {...(className ? { className, } : null)}>
      {url ? <LinkStyled href={url} content={name} onClick={onClick} /> : `${name}`}
    </address>
  );
}

Credit.propTypes = {
  ...creditPropTypes,
  /**
   * CSS class names provided by Fela
   */
  className: PropTypes.string,
};

Credit.defaultProps = {
  ...creditDefaultProps,
  className: null,
};

export default Credit;
export { creditPropTypes, creditDefaultProps, };
