import React from 'react';
import PropTypes from 'prop-types';
import { FelaComponent, } from 'react-fela';
import { parseStyleProps, parseTypographyProp, } from '@haaretz/htz-css-tools';
import Credit, { creditPropTypes, creditDefaultProps, } from './Credit';
import { stylesPropType, } from '../../propTypes/stylesPropType';

CreditArticle.propTypes = {
  ...creditPropTypes,
  /**
   * A special property holding miscellaneous CSS values that
   * trumps all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,
  useDefaultType: PropTypes.bool,
};

CreditArticle.defaultProps = {
  ...creditDefaultProps,
  miscStyles: null,
  useDefaultType: true,
};

const style = ({ miscStyles, theme, useDefaultType, }) => ({
  fontWeight: 'bold',
  extend: [
    useDefaultType ? parseTypographyProp(theme.articleStyle.header.bylineFontSize, theme.type) : {},
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});

export default function CreditArticle({ contentName, url, miscStyles, onClick, useDefaultType, }) {
  return (
    <FelaComponent miscStyles={miscStyles} useDefaultType={useDefaultType} style={style}>
      {({ className, }) => (
        <Credit className={className} contentName={contentName} url={url} onClick={onClick} />
      )}
    </FelaComponent>
  );
}
