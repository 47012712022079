// import { useMemo, useEffect, } from 'react';
// import { useQuery, } from 'react-apollo';
// import gql from 'graphql-tag';
// import { useEventTracker, } from '../../utils/EventTracker';

// const IS_BLOCKED_QUERY = gql`query GetPageDataClient {
//   isBlocked @client
// }`;

export default function useShouldDisplayOpeningElement(headlineElement) {
  const shouldDisplayOpeningElement = true;

  // **** NOTE: we keep the following code for future use. ****
  // const { biImpression, } = useEventTracker();
  // const { data, } = useQuery(IS_BLOCKED_QUERY);
  // const { isBlocked, } = data || { isBlocked: false, };

  // const souldDisplayOpeningElement = useMemo(() => {
  //   const rand = Math.random();

  //   return isBlocked
  //     ? rand >= 0.5
  //     : true;
  // }, [ isBlocked, ]);

  // useEffect(() => {
  //   // If headline-element exists on a blocked article, its an Image (implemented on Brightspot)
  //   // otherwise headline-element is null on blocked article.
  //   if (isBlocked && headlineElement) {
  //     biImpression({
  //       featureType: 'Content',
  //       feature: 'Show opening element',
  //       abTestName: 'Image in closed article',
  //       abGroup: souldDisplayOpeningElement ? 'Show image in closed article' : 'Dont show image in closed article',
  //     });
  //   }
  // }, [ biImpression, data, headlineElement, isBlocked, souldDisplayOpeningElement, ]);

  return shouldDisplayOpeningElement;
}
