import React, { useState, useRef, useEffect, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { parseStyleProps, borderVertical, borderBottom, } from '@haaretz/htz-css-tools';
import gql from 'graphql-tag';

import config from 'config';
import dynamic from 'next/dynamic';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import Query from '../ApolloBoundary/Query';

import CreditArticle from '../Credit/CreditArticle';
// import Alerts from '../Alerts/Alerts';
import AuthorAlert from '../Alerts/AuthorAlert';
import Image from '../Image/Image';
import TeaserTime from '../TeaserTime/TeaserTime';
import AuthorNotificationsRegistration from '../ServiceByMailRegistration/AuthorNotificationsRegistration';
import SlideinBox from '../Transitions/SlideinBox';
import EventTracker from '../../utils/EventTracker';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import useDarkModeChecker from '../../hooks/useDarkModeChecker';
import useAuthorsData from '../../hooks/Page/useAuthorsData';
import useArticleHeaderData from '../../hooks/Page/useArticleHeaderData';
import useIsLabel from '../../hooks/Page/useIsLabel';
import useWebViewChecker from '../../hooks/useWebViewChecker';

const AuthorTooltipHTZ = dynamic(import('./AuthorTooltip'), {
  loading: () => null,
  ssr: false,
});

const AuthorTooltipHDC = dynamic(import('./AuthorTooltipHDC'), {
  loading: () => null,
  ssr: false,
});

const siteNumber = config.has('siteNumber') ? config.get('siteNumber') : 80;

const authorTooltipMap = new Map([
  [ 80, AuthorTooltipHTZ, ],
  [ 85, AuthorTooltipHDC, ],
]);

const AuthorTooltip = authorTooltipMap.get(siteNumber) || AuthorTooltipHTZ;

const PLATFORM_QUERY = gql`
  query GetPlatform {
    platform @client
  }
`;

const outerStyle = ({ theme, miscStyles, }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  extend: [
    ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
  ],
});
const wrapperStyle = ({ theme, variationMq, centerVariationB, isDarkMode, border, }) => ({
  display: 'flex',
  alignItems: 'center',
  extend: [
    ...(variationMq.oldA
      ? [
        theme.mq(variationMq.oldA, {
          flexGrow: 1,
          justifyContent: 'space-between',
          ...isDarkMode ? { paddingTop: '1rem', } : {},
          ...border({
            width: '1px',
            lines: 1,
            style: 'solid',
            color: theme.color('articleHeader', 'metaBorder'),
          }),
        }),
      ]
      : []),
    ...(variationMq.a
      // the media query rule is passed through the variationMq.a prop,
      // this pattern is used through the whole component
      ? [
        theme.mq(variationMq.a, {
          flexGrow: 1,
          justifyContent: 'space-between',
        }),
      ]
      : []),
    ...(variationMq.b
      ? [
        theme.mq(variationMq.b, {
          justifyContent: centerVariationB ? 'center' : 'flex-start',
          alignItems: 'flex-end',
        }),
      ]
      : []),
    ...(variationMq.c
      ? [
        theme.mq(variationMq.c, {
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }),
      ]
      : []),
  ],
});

// the time needs to render in different locations for mobile
// mobileTime indicates if this is the mobile location
const timeStyle = ({ mobileTime, }) => ({ theme, variationMq, }) => ({
  fontFamily: theme.fontStacks[theme.framedFont],
  color: theme.color('articleHeader', 'metaTime'),
  marginInlineEnd: '1rem',
  extend: [
    ...(variationMq.oldA
      ? [
        theme.mq(variationMq.oldA, {
          display: mobileTime ? 'block' : 'none',
        }),
      ]
      : []),
    ...(variationMq.a
      ? [
        theme.mq(variationMq.a, {
          display: mobileTime ? 'inline-block' : 'none',
          color: theme.color('articleHeader', 'aVariationDates'),
          '&:nth-of-type(2n)': {
            ...theme.type(-3, { until: 's', lines: 2, }),
          },
          extend: [
            theme.type(-3, { until: 's', }),
            theme.type(-3, { from: 's', }),
          ],
        }),
      ]
      : []),
    ...(variationMq.b
      ? [
        theme.mq(variationMq.b, {
          marginInlineStart: '1rem',
          marginInlineEnd: '1rem',
          ...(mobileTime ? {} : { display: 'none', }),
        }),
      ]
      : []),
    ...(variationMq.c
      ? [
        theme.mq(variationMq.c, {
          marginTop: '0.5rem',
          display: mobileTime ? 'none' : 'block',
        }),
      ]
      : []),
    theme.type(-2, { untilBp: 'xl', }),
    theme.type(-3, { fromBp: 'xl', }),
  ],
});

const imageAuthorsAndMobileTimeContStyle = ({ theme, variationMq, }) => ({
  display: 'flex',
  alignItems: 'center',
  extend: [
    theme.type(-2, { untilBp: 's', }),
    theme.type(-1, { fromBp: 's', }),
    ...(variationMq.c
      ? [
        theme.mq(variationMq.c, {
          flexDirection: 'column',
          alignItems: 'flex-start',
        }),
      ]
      : []),
  ],
});

const authorsAndTimeContStyle = ({ theme, variationMq, }) => ({
  color: theme.color('credit', 'creditArticleText'),
  extend: [
    ...(variationMq.oldA ? [ theme.mq(variationMq.oldA, {}), ] : []),
    ...(variationMq.a ? [ theme.mq(variationMq.a, {
      maxWidth: '56vw',
      textAlign: 'start',
      '& address': {
        color: theme.color('bodyText'),
      },
      '& address a': {
        textDecorationLine: 'underline',
        textDecorationColor: theme.color('primary', '-3'),
        textUnderlineOffset: '1px',
        extend: [ theme.getTransition(0, 'swiftOut'), ],
        '&:hover': {
          textDecorationLine: 'underline',
          textDecorationColor: theme.color('primary', '-3', 0),
        },
      },
    }), ] : []),
    ...(variationMq.b ? [ theme.mq(variationMq.b, {}), ] : []),
    ...(variationMq.c
      ? [
        theme.mq(variationMq.c, {
          marginTop:
              theme.articleStyle.header.articleHeaderMetaCreditMarginTop,
        }),
      ]
      : []),
  ],
});

const alertsAndDesktopTimeContStyle = ({ theme, variationMq, }) => ({
  extend: [
    ...(variationMq.oldA
      ? [ theme.mq(variationMq.oldA, { marginInlineStart: 'auto', }), ]
      : []),
    ...(variationMq.a
      ? [ theme.mq(variationMq.a, { marginInlineStart: 'auto', }), ]
      : []),
    ...(variationMq.b ? [ theme.mq(variationMq.b, { marginStart: '0', }), ] : []),
    ...(variationMq.c ? [ theme.mq(variationMq.c, { marginTop: '1rem', }), ] : []),
  ],
});

/* eslint-disable react/prop-types */
function DateSeparator({ variationMq, }) {
  const { css, theme, } = useFela();

  return variationMq.a ? (
    <span
      className={css({
        display: 'none',
        extend: [
          theme.mq(variationMq.a, {
            display: 'inline-block',
            verticalAlign: 'middle',
            alignSelf: 'center',
            flexShrink: '0',
            width: '4px',
            height: '4px',
            backgroundColor: 'currentColor',
            opacity: '0.85',
            borderRadius: '50%',
            marginInlineEnd: '1rem',
          }),
        ],
      })}
    />
  ) : null;
}

/* eslint-disable react/prop-types */
function DisplayDates({
  publishDate,
  modifiedDate,
  className,
  variationMq,
  formatTime,
  withSeparator,
}) {
  const { css, theme, } = useFela();
  const wrapperClassName = css({
    extend: [
      ...(variationMq.oldA ? [ theme.mq(variationMq.oldA, { display: 'block', }), ] : []),
      ...(variationMq.a ? [ theme.mq(variationMq.a, {
        lineHeight: 1,
        paddingInlineEnd: '1rem',
      }), ] : []),
      ...(variationMq.b ? [ theme.mq(variationMq.b, {
        display: 'inline-flex',
        marginInlineStart: '1rem',
      }), ] : []),
      ...(variationMq.c ? [ theme.mq(variationMq.c, { marginTop: '0.5rem', }), ] : []),
    ],
  });

  return (
    <div
      data-display-dates
      className={css({
        extend: [
          ...(variationMq.oldA ? [ theme.mq(variationMq.oldA, {}), ] : []),
          ...(variationMq.a ? [
            theme.mq(variationMq.a, {
              marginTop: '1px',
              extend: [
                theme.mq({ from: 's', }, { marginTop: '0.5rem', }),
              ],
            }),
          ] : []),
          ...(variationMq.b
            ? [ theme.mq(variationMq.b, { display: 'inline', }), ]
            : []),
          ...(variationMq.c ? [ theme.mq(variationMq.c, {}), ] : []),
          theme.type(-2, { untilBp: 'xl', }),
          theme.type(-3, { fromBp: 'xl', }),
        ],
      })}
    >
      <TeaserTime
        publishDate={publishDate}
        lastUpdate={modifiedDate}
        className={className}
        twoDatesWrapperClassName={wrapperClassName}
        labels={theme.timeLabels}
        isNewFullDateFormat
        format={formatTime}
        separator={withSeparator ? <DateSeparator variationMq={variationMq} /> : null}
      />
    </div>
  );
}
/* eslint-enable react/prop-types */

function ArticleHeaderMeta({
  biAction,
  isPhotoBlog,
  miscStyles,
  platform,
  variationMq,
  centerVariationB,
  showDate,
  formatTime,
}) {
  const [ skipFocusAuthorsBtn, setSkipFocusAuthorsBtn, ] = useState(true);
  const [ isShowAuthorAlertsForm, setIsShowAuthorAlertsForm, ] = useState(false);
  const alertsToggleBtnRef = useRef();
  const isDarkMode = useDarkModeChecker();
  const header = useArticleHeaderData();
  const isLabel = useIsLabel();
  const authors = useAuthorsData();
  const isWebView = useWebViewChecker();

  const authorIsBlogSection = authors?.[0]?.inputTemplate === 'BlogSection';

  const reportingFrom = header?.reportingFrom;
  const publishDate = header?.datePublishedISO;
  const modifiedDate = header?.dateModifiedISO;

  const { css, theme, } = useFela({
    miscStyles,
    variationMq,
    centerVariationB,
    isLabel,
    isDarkMode,
    border: isDarkMode ? borderBottom : borderVertical,
  });
  const getComponent = useGetComponent();

  useEffect(() => {
    if (
      !skipFocusAuthorsBtn
      && !isShowAuthorAlertsForm
      && alertsToggleBtnRef.current
    ) {
      alertsToggleBtnRef.current.focus();
    }
  }, [
    authors,
    biAction,
    isShowAuthorAlertsForm,
    platform,
    skipFocusAuthorsBtn,
  ]);

  const toggleAuthorAlertsForm = () => {
    if (skipFocusAuthorsBtn) setSkipFocusAuthorsBtn(false);
    setIsShowAuthorAlertsForm(prevState => !prevState);
  };

  const Logo = getComponent('articleHeaderMetaLogo');

  return (
    Array.isArray(authors)
    && authors.length > 0 && (
      <div className={`${css(outerStyle)} ${isPhotoBlog ? 'isPhotoBlog' : ''}`}>
        <div className={css(wrapperStyle)}>
          <div className={css(imageAuthorsAndMobileTimeContStyle)}>
            {/*  Author image */}
            {isLabel || authors.length > 1 || !authors[0].image ? (
              <Logo
                color={[ 'articleHeader', 'logoColor', ]}
                size={[
                  { until: 'l', value: 6.5, },
                  { from: 'l', value: 10, },
                ]}
                miscStyles={{
                  display: [
                    ...(variationMq.b
                      ? [ { ...variationMq.b, value: 'none', }, ]
                      : []),
                    ...(variationMq.c
                      ? [ { ...variationMq.c, value: 'block', }, ]
                      : []),
                  ],
                  marginInlineEnd: '1rem',
                }}
              />
            ) : (
              <Image
                image={authors[0].image}
                imgOptions={{
                  transforms: {
                    width: '100',
                    aspect: 'square',
                    quality: 'auto',
                    gravity: 'face',
                  },
                }}
                miscStyles={{
                  width: [
                    ...(variationMq.oldA
                      ? [ { ...variationMq.oldA, value: '6rem', }, ]
                      : []),
                    ...(variationMq.a
                      ? [ { ...variationMq.a, value: '6rem', }, ]
                      : []),
                    ...(variationMq.b
                      ? [ { ...variationMq.b, value: '6rem', }, ]
                      : []),
                    ...(variationMq.c
                      ? [
                        { ...variationMq.c, until: 'xl', value: '11rem', },
                        { from: 'xl', value: '10rem', },
                      ]
                      : []),
                  ],
                  height: [
                    ...(variationMq.oldA
                      ? [ { ...variationMq.oldA, value: '6rem', }, ]
                      : []),
                    ...(variationMq.a
                      ? [ { ...variationMq.a, value: '6rem', }, ]
                      : []),
                    ...(variationMq.b
                      ? [ { ...variationMq.b, value: '6rem', }, ]
                      : []),
                    ...(variationMq.c
                      ? [
                        { ...variationMq.c, until: 'xl', value: '11rem', },
                        { from: 'xl', value: '10rem', },
                      ]
                      : []),
                  ],
                  paddingBottom: '6rem',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  display: [
                    ...(variationMq.b
                      ? [ { ...variationMq.b, value: 'none', }, ]
                      : []),
                    ...(variationMq.c
                      ? [ { ...variationMq.c, value: 'block', }, ]
                      : []),
                  ],
                  marginInlineEnd: '1rem',
                  ...theme.mq({ until: 's', }, {
                    marginInlineEnd: '1.5rem',
                  }),
                }}
              />
            )}
            {/* Author name and publish-date */}
            <div className={css(authorsAndTimeContStyle)}>
              {(authors || []).map((author, idx) => (
                <CreditArticle
                  key={author.contentId || author.name}
                  contentName={author.name || author.contentName}
                  url={author.url}
                  onClick={
                    biAction
                      ? () => biAction({
                        actionCode: 154,
                        writerId: author.contentId,
                        writerName: author.contentName,
                        feature: 'writer byline',
                        featureType: 'Content',
                      })
                      : null
                  }
                  miscStyles={{
                    // fontWeight: 700,
                    ...(isPhotoBlog
                      ? { color: theme.color('primary', '-1'), }
                      : {}),
                    ':after': {
                      content:
                        idx === authors.length - 1
                          ? null
                          : authors.length > 1
                            ? authors.length - 2 === idx
                              ? `" ${theme.words.and}"`
                              : '", "'
                            : null,
                    },
                    display: 'inline',
                    type: theme.articleStyle.header.authorFontSize,
                  }}
                />
              ))}
              {reportingFrom ? (
                <div
                  className={css({
                    color: theme.color('bodyText'),
                    extend: [
                      theme.type(-2, { fromBp: 'xl', }),
                      theme.type(-1, {
                        fromBp: 's',
                        untilBp: 'xl',
                      }),
                      theme.type(-2, { untilBp: 's', }),
                      ...(variationMq.c
                        ? [ theme.mq(variationMq.c, { display: 'block', }), ]
                        : []),
                    ],
                  })}
                >
                  {reportingFrom}
                </div>
              ) : null}
              {showDate ? (
                <DisplayDates
                  publishDate={publishDate}
                  modifiedDate={modifiedDate}
                  className={css(timeStyle({ mobileTime: true, }))}
                  withSeparator
                  variationMq={variationMq}
                  formatTime={formatTime}
                />
              ) : null}
            </div>
          </div>
          {isLabel ? (
            <div
              className={css({
                display: 'flex',
                flexGrow: '1',
                color: theme.color('labels', 'relatedArticleTitle'),
                fontWeight: 'bold',
                extend: [
                  ...(variationMq.oldA
                    ? [ theme.mq(variationMq.oldA, { justifyContent: 'flex-end', }), ]
                    : []),
                  ...(variationMq.a
                    ? [ theme.mq(variationMq.a, { justifyContent: 'flex-end', }), ]
                    : []),
                  ...(variationMq.b
                    ? [
                      theme.mq(variationMq.b, {
                        alignSelf: 'flex-start',
                        marginStart: showDate ? null : '1rem',
                      }),
                    ]
                    : []),
                  ...(variationMq.c
                    ? [
                      theme.mq(variationMq.c, {
                        marginTop: '1rem',
                        marginBottom: '1rem',
                      }),
                    ]
                    : []),
                  theme.type(-1),
                  theme.type(-2, { fromBp: 'xl', }),
                ],
              })}
            >
              {theme.labelsI18n.promotedContent}
            </div>
          ) : null}
          {/* alerts and desktop time */}
          {authors.length === 1 && (authors[0].disableAlerts === false || (!isWebView && authorIsBlogSection)) ? (
            <div className={css(alertsAndDesktopTimeContStyle)}>
              <AuthorAlert
                isBlog={authorIsBlogSection}
                origin={authorIsBlogSection ? 'blog-alert-article' : 'author-alert-article'}
                author={authors[0]}
                platform={platform}
                biAction={biAction}
                ref={alertsToggleBtnRef}
                onToggle={() => toggleAuthorAlertsForm()}
                miscStyles={({ status, }) => {
                  const isFollowing = status === 'Follow';
                  const color = (isFollowing || isPhotoBlog) || !variationMq.a
                    ? null
                    : theme.color('list', 'listViewHeader');
                  const activeColor = color
                    ? [
                      {
                        ...variationMq.a,
                        value: { color: `${color}!important`, },
                      },
                    ]
                    : isPhotoBlog && !isFollowing
                      ? { color: `${theme.color('quaternary')}!important`, }
                      : null;
                  return {
                    fontFamily: theme.fontStacks[theme.framedFont],
                    ...(isPhotoBlog
                      ? {
                        color: theme.color('white'),
                        '&:visited': activeColor,
                        '&:active': activeColor,
                        '&:focus': activeColor,
                        '&:hover': activeColor,
                      }
                      : {}),
                    display: 'flex',
                    ...(variationMq.oldA ? {
                      flexDirection: [ { ...variationMq.oldA, value: 'column', }, ],
                      alignItems: [ { ...variationMq.oldA, value: 'center', }, ],
                    } : {}),
                    ...(variationMq.a
                      ? {
                        alignItems: [ { ...variationMq.a, value: 'center', }, ],
                        type: [ { ...variationMq.a, value: -3, }, ],
                        ...(color ? {
                          color: [ { ...variationMq.a, value: color, }, ],
                          '&:visited': activeColor,
                          '&:active': activeColor,
                          '&:focus': activeColor,
                          '&:hover': activeColor,
                        } : {}),
                      }
                      : {}),
                  };
                }}
                iconMiscStyles={{
                  marginInlineEnd: [
                    ...(variationMq.b
                      ? [ { ...variationMq.b, value: '0.3rem', }, ]
                      : []),
                    ...(variationMq.c
                      ? [ { ...variationMq.c, value: '0.5rem', }, ]
                      : []),
                  ],
                  ...(theme.direction === 'ltr'
                    ? {
                      transform: [
                        ...(variationMq.b
                          ? [ { ...variationMq.b, value: 'translateY(1px)', }, ]
                          : []),
                        ...(variationMq.c
                          ? [ { ...variationMq.c, value: 'translateY(1px)', }, ]
                          : []),
                      ],
                    }
                    : {}),
                }}
                iconSize={[
                  ...(variationMq.oldA ? [ { ...variationMq.oldA, value: 3, }, ] : []),
                  ...(variationMq.a ? [ { ...variationMq.a, value: 3.3, }, ] : []),
                  ...(variationMq.b ? [ { ...variationMq.b, value: 2.5, }, ] : []),
                  ...(variationMq.c ? [ { ...variationMq.c, value: 2.5, }, ] : []),
                ]}
                modalVariationMq={variationMq.c}
              />
            </div>
          ) : null}
          {showDate ? (
            <DisplayDates
              publishDate={publishDate}
              modifiedDate={modifiedDate}
              className={css(timeStyle({ mobileTime: false, }))}
              variationMq={variationMq}
              formatTime={formatTime}
            />
          ) : null}
        </div>
        {authors?.length === 1 && authors[0]?.disableAlerts === false ? (<AuthorTooltip author={authors[0]} />) : null}
        {/* Author notifaction dialog until M breakpoint. The L and XL modal break points login at <AuthorAlert /> */}
        <SlideinBox
          show={authors[0].disableAlerts === false && isShowAuthorAlertsForm}
          duration={2}
          focus
          maxHeight={100}
        >
          <AuthorNotificationsRegistration
            author={authors[0]}
            platform={platform}
            biAction={biAction}
            onToggle={() => toggleAuthorAlertsForm()}
            origin="author-alert-article"
          />
        </SlideinBox>
        {/* End of Author notifaction dialog until M breakpoint */}
      </div>
    )
  );
}

const variationMqType = PropTypes.shape({
  until: PropTypes.oneOf([ 's', 'm', 'l', 'xl', ]),
  from: PropTypes.oneOf([ 's', 'm', 'l', 'xl', ]),
});

ArticleHeaderMeta.propTypes = {
  /** affects the styling */
  isPhotoBlog: PropTypes.bool,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,
  /**
   * This component has three view variation,
   * by default:
   * variation a is used up to 's' bp,
   * variation b is used from 's' to 'l',
   * variation c from: 'l'.
   * In cases that we want to use each view from different  breakpoints,
   * we pass the variationMq prop, specifying which variation should be used for each bp.
   * you can skip one of the variation if not needed but make sure to cover all breakpoints
   * e.g :
   * variationMq: {
   * a: { until: 's', },
   * b: {},
   * c: { from: 's', },
   *   },
   *
   */
  variationMq: PropTypes.shape({
    oldA: variationMqType,
    a: variationMqType,
    b: variationMqType,
    c: variationMqType,
  }).isRequired,
  /**
   * A variation on top of a variation.
   * When true, content in variation B will be centered.
   * false by default.
   */
  centerVariationB: PropTypes.bool,

  showDate: PropTypes.bool,
  /**
   * The output format for the time prop.<br/>
   * Can be a __string__ or an array of __DateFormatRule__ objects({ from, until, format }).
   * For more details about formatting [read date-fns docs](https://date-fns.org/v1.29.0/docs/format)
   */
  formatTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        from: PropTypes.string,
        until: PropTypes.string,
        format: PropTypes.string,
      })
    ),
  ]),
};

ArticleHeaderMeta.defaultProps = {
  isPhotoBlog: false,
  miscStyles: null,
  centerVariationB: false,
  showDate: true,
  formatTime: null,
};

WrappedArticleHeaderMeta.propTypes = {
  variationMq: PropTypes.shape({
    a: variationMqType,
    b: variationMqType,
    c: variationMqType,
  }),
};
function WrappedArticleHeaderMeta({ variationMq, ...props }) {
  const { theme, } = useFela();
  const isLabel = useIsLabel();

  const safeVariationMq = theme.articleHeaderVariationAType || isLabel
    ? {
      [theme.articleHeaderVariationAType || 'oldA']: variationMq.a,
      b: variationMq.b,
      c: variationMq.c,
    }
    : variationMq;
  return (
    <Query query={PLATFORM_QUERY}>
      {({ loading, error, data, client, }) => {
        if (loading) return null;
        if (error) console.log(error);
        const { platform, } = data;
        return (
          <EventTracker>
            {({ biAction, }) => (
              <ArticleHeaderMeta
                {...props}
                variationMq={safeVariationMq}
                platform={platform}
                biAction={biAction}
              />
            )}
          </EventTracker>
        );
      }}
    </Query>
  );
}

WrappedArticleHeaderMeta.defaultProps = {
  variationMq: {
    a: { until: 's', },
    b: { from: 's', until: 'l', },
    c: { from: 'l', },
  },
};

export default WrappedArticleHeaderMeta;
